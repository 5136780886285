import Flickity from 'Flickity'

function DOMready(callback) {
  document.readyState !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const optionsFlkty = {
    cellAlign: 'left',
    prevNextButtons: true,
    wrapAround: true,
    pageDots: false,
    // dragThreshold: 1,
    // selectedAttraction: 0.2,
    // friction: 0.9,
  }

  const carouselArray = document.querySelectorAll('.before-after__carousel')

  if (carouselArray.length > 0) {
    carouselArray.forEach((carousel) => {
      // eslint-disable-next-line no-unused-vars
      new Flickity(carousel, optionsFlkty).resize()
    })
  }
})
